export const environment = {
    production: true,
    secureStorage: true,
    displayAppState: false,
    contextRoot: 'esales-rider',
    version: '2024.12.12',
    apiUrl: 'https://apitest.prcins.net/ext/rider/qa2/secure',
    homePageUrl: 'https://plymouthrock.com',
    sessionExpTime: 2700,
    sessionExpWarningTime: 60,
    policyEffectiveDateMaxDays: 100,
    faqURL: 'https://www.plymouthrock.com/resource-center/esales-faqs',
    glossaryURL: 'http://www.plymouthrock.com/resource-center/glossary',
    getEffPropsByKey: 'https://apitest.prcins.net/ext/rider/qa2/secure/getEffPropsByKey/',
    enableTealium: true,
    tealiumAccount: 'plymouth',
    tealiumProfile: 'esales',
    tealiumEnvironment: 'dev',
    enableDoubleClick: false,
    cobrandURL: 'https://model.plymouthrock.com/public/Plymouthrock/StaticFiles/esales/brand/',
    homecrossellurl: 'https://homeownersmod3.plymouthrock.com/consumer/home/#/retreive/policy',
    eServiceregistrationURL: 'https://ciqa1.plymouthrock.com/eservice/register?',
    docPDFsURL: 'https://apitest.prcins.net/ext/rider/qa2/secure/ui/esign/getSafePDFDoc/',
    //docPDFsURL: '/esales-rider-ws/secure/ui/esign/getSafePDFDoc/',
    homeQuoteUrl: 'http://co1jwalqv73:8680/consumer/home/#/homeowner/purchase?policyNumber=',
    evidenceURL: 'https://apitest.prcins.net/ext/rider/qa2/secure/ui/esign/pdf/',
    printPDFsURL: 'https://apitest.prcins.net/ext/rider/qa2/secure/ui/pdf/safemultiquote/',
    monetizationURL: 'https://www.plymouthrock.com/resource-center/nj-insurance-guides/lp/moto-click?zip_code=',
    eSalesQuoteRetrievalUrl: 'http://es3qa.plymouthrock.com/esales/entry/retrievequote',
    enableStateGate: false,
    enableSaveFormChangesConfirmation: false,
    xhrWithCredentials: false,
    isSpeedTrackerEnabled: false,
    isCPTrackerEnabled: true,
    profile: 'qa2' 
};